@use '@sats-group/ui-lib/tokens/spacing';
@use '../../breakpoints.scss';

.content-container {
  margin: 0 auto;
  max-width: 800px;
  padding-left: spacing.$m;
  padding-right: spacing.$m;
  width: 100%; // NOTE: This is needed for rendering inside flexbox containers

  &--wide {
    max-width: 1000px;
  }

  &--full-width {
    max-width: 1280px;
  }

  &--no-padding-mobile {
    @include breakpoints.break {
      padding: 0;
    }
  }
}
