@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.profile-section {
  padding: spacing.$xs spacing.$m spacing.$m;
  background-color: light.$surface-primary-default;
  border-radius: corner-radius.$s;

  &__header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: spacing.$xs;
    align-items: center;
  }

  &__header-text {
    display: flex;
    gap: spacing.$xxs;
  }

  &__required {
    color: light.$ge-icons-negative;
  }

  &__buttons {
    display: flex;
    gap: spacing.$xs;
  }

}
