@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: spacing.$s;
  padding: spacing.$xl;

  &__content {
    padding: spacing.$m 0;
    max-width: 450px;
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: spacing.$l;
    background-color: light.$signal-surface-information;
    color: light.$on-signal-surface-information-alternate;
    border-radius: 50%;
  }
}
