.form-actions {
  position: relative;
}
.form-actions__buttons {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.form-actions__buttons > * {
  max-width: 150px;
  width: 100%;
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9mb3JtLWFjdGlvbnMiLCJzb3VyY2VzIjpbImZvcm0tYWN0aW9ucy5zY3NzIiwiLi4vLi4vLi4vbm9kZV9tb2R1bGVzL0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7O0FBRUE7RUFDRTtFQUNBLEtDTEM7RURNRDs7QUFFQTtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgJ0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZyc7XG5cbi5mb3JtLWFjdGlvbnMge1xuICBwb3NpdGlvbjogcmVsYXRpdmU7XG5cbiAgJl9fYnV0dG9ucyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBnYXA6IHNwYWNpbmcuJHhzO1xuICAgIGZsZXgtd3JhcDogd3JhcDtcblxuICAgID4gKiB7XG4gICAgICBtYXgtd2lkdGg6IDE1MHB4O1xuICAgICAgd2lkdGg6IDEwMCU7XG4gICAgICBmbGV4OiAxO1xuICAgIH1cbiAgfVxufVxuIiwiLy8gaHR0cHM6Ly93d3cuZmlnbWEuY29tL2ZpbGUvV3pLQ3dSWTA5em40cnpSVmZZMFl2ZFJ0L3NhdHMtZHMtc3R5bGVzP25vZGUtaWQ9NDA4JTNBODVcbiR4eHM6IDRweDtcbiR4czogOHB4O1xuJHM6IDEycHg7XG4kbTogMTZweDtcbiRsOiAyNHB4O1xuJHhsOiAzMnB4O1xuJHh4bDogNjRweDtcbiR4eHhsOiAxMjhweDtcblxuJGhlcm86IDI1NnB4O1xuJG1pbmltdW0tdmlld3BvcnQtd2lkdGg6IDMyMHB4O1xuIl19 */