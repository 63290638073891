@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.member-contact-information {
  &__detail {
    color: light.$on-surface-primary-alternate;
  }

  &__contact {
    display: flex;
    align-content: center;
    margin-right: spacing.$l;
    word-break: break-all;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__contact-information {
    grid-area: contact;
    display: flex;
    flex-wrap: wrap;
  }

  &__contact-icon {
    display: flex;
    align-items: center;
    margin-right: spacing.$xs;
  }

  &__information {
    display: grid;
    grid-template-areas: 'image details';
    grid-template-columns: auto 1fr;
    gap: spacing.$m;

    @media (min-width: 500px) {
      grid-template-areas: 'image details';
    }
  }

  &__basic-information {
    grid-area: details;
  }

  &__image-wrapper {
    grid-area: image;
    border-radius: corner-radius.$m;
    width: 80px;
    height: 80px;
    overflow: hidden;
    flex-shrink: 0;
    color: light.$on-surface-primary-alternate;
    background-color: light.$surface-primary-default;
  }

  &__tag-wrapper {
    display: flex;
  }
}
