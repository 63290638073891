@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';

.product {
  &__texts {
    margin-bottom: spacing.$xs;
  }

  &__detail {
    color: light.$on-surface-primary-alternate;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
