@use '@sats-group/ui-lib/tokens/spacing';

.profile-list-item {

  &__modal-inner {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__modal-header {
    margin-bottom: spacing.$s;
  }

  &__section-placeholder {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__list {
    margin: 0;

    > * + * {
      margin-top: spacing.$s;
    }
  }
}
