.profile-list-item__modal-inner {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.profile-list-item__modal-header {
  margin-bottom: 12px;
}
.profile-list-item__section-placeholder {
  display: flex;
  align-items: center;
  gap: 12px;
}
.profile-list-item__list {
  margin: 0;
}
.profile-list-item__list > * + * {
  margin-top: 12px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9wcm9maWxlLWxpc3QtaXRlbSIsInNvdXJjZXMiOlsicHJvZmlsZS1saXN0LWl0ZW0uc2NzcyIsIi4uLy4uLy4uL25vZGVfbW9kdWxlcy9Ac2F0cy1ncm91cC91aS1saWIvdG9rZW5zL3NwYWNpbmcuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFJRTtFQUNFO0VBQ0E7RUFDQSxLQ0pBOztBRE9GO0VBQ0UsZUNSQTs7QURXRjtFQUNFO0VBQ0E7RUFDQSxLQ2RBOztBRGlCRjtFQUNFOztBQUVBO0VBQ0UsWUNyQkYiLCJzb3VyY2VzQ29udGVudCI6WyJAdXNlICdAc2F0cy1ncm91cC91aS1saWIvdG9rZW5zL3NwYWNpbmcnO1xuXG4ucHJvZmlsZS1saXN0LWl0ZW0ge1xuXG4gICZfX21vZGFsLWlubmVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgZ2FwOiBzcGFjaW5nLiRzO1xuICB9XG5cbiAgJl9fbW9kYWwtaGVhZGVyIHtcbiAgICBtYXJnaW4tYm90dG9tOiBzcGFjaW5nLiRzO1xuICB9XG5cbiAgJl9fc2VjdGlvbi1wbGFjZWhvbGRlciB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGdhcDogc3BhY2luZy4kcztcbiAgfVxuXG4gICZfX2xpc3Qge1xuICAgIG1hcmdpbjogMDtcblxuICAgID4gKiArICoge1xuICAgICAgbWFyZ2luLXRvcDogc3BhY2luZy4kcztcbiAgICB9XG4gIH1cbn1cbiIsIi8vIGh0dHBzOi8vd3d3LmZpZ21hLmNvbS9maWxlL1d6S0N3UlkwOXpuNHJ6UlZmWTBZdmRSdC9zYXRzLWRzLXN0eWxlcz9ub2RlLWlkPTQwOCUzQTg1XG4keHhzOiA0cHg7XG4keHM6IDhweDtcbiRzOiAxMnB4O1xuJG06IDE2cHg7XG4kbDogMjRweDtcbiR4bDogMzJweDtcbiR4eGw6IDY0cHg7XG4keHh4bDogMTI4cHg7XG5cbiRoZXJvOiAyNTZweDtcbiRtaW5pbXVtLXZpZXdwb3J0LXdpZHRoOiAzMjBweDtcbiJdfQ== */