@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.tabs {
  $breakpoint: 400px;
  box-shadow: inset 0px -2px 0px 0px light.$ge-divider-default;

  &__inner {
    display: flex;
  }

  &__item {
    padding: spacing.$xs spacing.$l;
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 2px solid light.$ge-divider-default;
    cursor: pointer;

    @media (max-width: $breakpoint) {
      padding: spacing.$xs spacing.$m;
      flex: 1;
    }

    &--active {
      border-color: light.$ge-selector-primary-selected-default;
    }
  }

  &__text {
    display: flex;
    gap: spacing.$xs;
    align-items: center;
    justify-content: center;
  }

  &__number {
    background: light.$ge-badge-secondary;
    color: light.$on-ge-on-badge-secondary;
    border-radius: 100%;
    padding: spacing.$xxs;
    height:20px;
    width:20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
