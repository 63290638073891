.member-action__buttons {
  display: flex;
  height: 100%;
}
.member-action__buttons > * {
  flex: 1;
}
.member-action__submit {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9tZW1iZXItYWN0aW9uIiwic291cmNlcyI6WyJtZW1iZXItYWN0aW9uLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUU7RUFDRTtFQUNBOztBQUNBO0VBQ0U7O0FBSUo7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgJ0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvY29ybmVyLXJhZGl1cyc7XG5AdXNlICdAc2F0cy1ncm91cC91aS1saWIvdG9rZW5zL3NwYWNpbmcnO1xuXG4ubWVtYmVyLWFjdGlvbiB7XG4gICZfX2J1dHRvbnMge1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgaGVpZ2h0OiAxMDAlO1xuICAgID4gKiB7XG4gICAgICBmbGV4OiAxO1xuICAgIH1cbiAgfVxuXG4gICZfX3N1Ym1pdCB7XG4gICAgd2lkdGg6IDEwMCU7XG4gIH1cbn1cbiJdfQ== */