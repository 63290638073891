@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '../../navigation-helpers';

.layout {
  $breakpoint: 1400px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__messages {
    position: fixed;
    max-width: 800px;
    width: 100%;
    top: navigation-helpers.$navMinHeight;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 spacing.$xs;
    z-index: 901;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__person {
    display: none;

    @media (min-width: $breakpoint) {
      display: flex;
      justify-content: center;
    }
  }

  &__person-icon {
    margin-right: spacing.$xs;
  }

  &__main {
    flex: 1;

    &--margin-top {
      margin-top: spacing.$l;
    }

    &--outline-header {
      border-top: 1px solid light.$ge-divider-default;
    }
  }

  &__banner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: spacing.$xxs;
    background-color: light.$ge-signal-warning;
    padding: spacing.$s;
  }
}
