@use '@sats-group/ui-lib/tokens/spacing';

.display-media {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);

  &__video-wrap {
    position: relative;
    width: 100%;
    max-width: 800px;
    min-width: 300px;
  }

  &__image-wrap {
    position: relative;
  }

  &__image {
    height: 100%;
    max-height: 100vh;
    min-width: 300px;
    width: 100%;
    object-fit: contain;
  }

  &__button-wrap {
    position: absolute;
    right: spacing.$s;
    top: spacing.$s;
  }
}
