@use '@sats-group/ui-lib/tokens/spacing';

.form-actions {
  position: relative;

  &__buttons {
    display: flex;
    gap: spacing.$xs;
    flex-wrap: wrap;

    > * {
      max-width: 150px;
      width: 100%;
      flex: 1;
    }
  }
}
