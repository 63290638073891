@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/elevation';

.action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: light.$surface-primary-default;
  padding: spacing.$m;
  z-index: 4;
  @include elevation.level(2);

  &__text {
    color: light.$on-surface-primary-alternate;
    margin-bottom: spacing.$s;
  }

  &__buttons {
    display: flex;
    gap: spacing.$s;
  }
}
