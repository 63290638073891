.display-media {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.35);
}
.display-media__video-wrap {
  position: relative;
  width: 100%;
  max-width: 800px;
  min-width: 300px;
}
.display-media__image-wrap {
  position: relative;
}
.display-media__image {
  height: 100%;
  max-height: 100vh;
  min-width: 300px;
  width: 100%;
  object-fit: contain;
}
.display-media__button-wrap {
  position: absolute;
  right: 12px;
  top: 12px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9kaXNwbGF5LW1lZGlhIiwic291cmNlcyI6WyJkaXNwbGF5LW1lZGlhLnNjc3MiLCIuLi8uLi8uLi9ub2RlX21vZHVsZXMvQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFFRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBR0Y7RUFDRTtFQUNBLE9DbENBO0VEbUNBLEtDbkNBIiwic291cmNlc0NvbnRlbnQiOlsiQHVzZSAnQHNhdHMtZ3JvdXAvdWktbGliL3Rva2Vucy9zcGFjaW5nJztcblxuLmRpc3BsYXktbWVkaWEge1xuXG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBwb3NpdGlvbjogZml4ZWQ7XG4gIHotaW5kZXg6IDE7XG4gIGhlaWdodDogMTAwJTtcbiAgd2lkdGg6IDEwMCU7XG4gIHRvcDogMDtcbiAgbGVmdDogMDtcbiAgYmFja2dyb3VuZC1jb2xvcjogcmdiYSgwLCAwLCAwLCAwLjM1KTtcblxuICAmX192aWRlby13cmFwIHtcbiAgICBwb3NpdGlvbjogcmVsYXRpdmU7XG4gICAgd2lkdGg6IDEwMCU7XG4gICAgbWF4LXdpZHRoOiA4MDBweDtcbiAgICBtaW4td2lkdGg6IDMwMHB4O1xuICB9XG5cbiAgJl9faW1hZ2Utd3JhcCB7XG4gICAgcG9zaXRpb246IHJlbGF0aXZlO1xuICB9XG5cbiAgJl9faW1hZ2Uge1xuICAgIGhlaWdodDogMTAwJTtcbiAgICBtYXgtaGVpZ2h0OiAxMDB2aDtcbiAgICBtaW4td2lkdGg6IDMwMHB4O1xuICAgIHdpZHRoOiAxMDAlO1xuICAgIG9iamVjdC1maXQ6IGNvbnRhaW47XG4gIH1cblxuICAmX19idXR0b24td3JhcCB7XG4gICAgcG9zaXRpb246IGFic29sdXRlO1xuICAgIHJpZ2h0OiBzcGFjaW5nLiRzO1xuICAgIHRvcDogc3BhY2luZy4kcztcbiAgfVxufVxuIiwiLy8gaHR0cHM6Ly93d3cuZmlnbWEuY29tL2ZpbGUvV3pLQ3dSWTA5em40cnpSVmZZMFl2ZFJ0L3NhdHMtZHMtc3R5bGVzP25vZGUtaWQ9NDA4JTNBODVcbiR4eHM6IDRweDtcbiR4czogOHB4O1xuJHM6IDEycHg7XG4kbTogMTZweDtcbiRsOiAyNHB4O1xuJHhsOiAzMnB4O1xuJHh4bDogNjRweDtcbiR4eHhsOiAxMjhweDtcblxuJGhlcm86IDI1NnB4O1xuJG1pbmltdW0tdmlld3BvcnQtd2lkdGg6IDMyMHB4O1xuIl19 */