@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.recurring-sessions-preview {
  $breakpoint: 500px;

  border-radius: corner-radius.$s;
  overflow: hidden;

  &__info,
  &__header-wrapper {
    display: flex;
    gap: spacing.$s;
  }

  &__info {
    align-items: center;
  }

  &__header-wrapper {
    justify-content: space-between;
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__preview {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;

    &--spacing {
      padding: spacing.$m;
    }
  }

  &__see-more {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: spacing.$xs;
  }

  &__no-conflicts {
    margin-top: spacing.$s;
  }

  &__conflicts {
    border: 1px solid light.$ge-border-default;
    border-radius: corner-radius.$xs;

    > * + * {
      border-top: 1px solid light.$ge-border-default;
    }

    @media (min-width: $breakpoint) {
      display: flex;

      > * + * {
        border-top: 0;
        border-left: 1px solid light.$ge-border-default;
      }
    }
  }

  &__conflicts-color-wrapper {
    display: flex;
    gap: spacing.$xs;
    padding: spacing.$xxs;
    align-items: center;
  }

  &__conflicts-color-red,
  &__conflicts-color-orange,
  &__conflicts-color-green {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    border-radius: corner-radius.$xs;
  }

  &__conflicts-color-red {
    background-color: light.$ge-signal-error;
  }

  &__conflicts-color-orange {
    background-color: light.$ge-signal-warning;
  }

  &__conflicts-color-green {
    background-color: light.$ge-signal-success;
  }
}
