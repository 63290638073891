@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.split-button {
  display: flex;
  position: relative;

  &__single-choice,
  &__multiple-choices {
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    position: relative;
    text-align: center;
    color: light.$on-buttons-on-complete-default;
    background: light.$buttons-complete-default;
    border: none;
    padding: spacing.$s;

    &:hover {
      background: light.$buttons-complete-hover;
    }
  }

  &__single-choice {
    border-radius: corner-radius.$s 0 0 corner-radius.$s;
    padding-left: spacing.$m;
  }

  &__multiple-choices {
    border-left: 2px solid light.$ge-divider-alternate;
    border-radius: 0 corner-radius.$s corner-radius.$s 0;
    padding-right: spacing.$m;
  }

  &__icon {
    margin-top: 2px; // NOTE: Hack to make it appear more center aligned
  }
}
