@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.recurring-info-modal {
  &__colors-wrapper {
    margin-bottom: spacing.$l;
    border: 1px solid light.$ge-border-default;
    border-radius: corner-radius.$s;

    > * + * {
      border-top: 1px solid light.$ge-border-default;
    }
  }

  &__color-wrapper {
    padding: spacing.$xs;
  }

  &__color-wrapper-top {
    display: flex;
    gap: spacing.$s;
  }

  &__color-wrapper-bottom-extras-wrapper {
    margin-top: spacing.$m;
  }

  &__color-wrapper-bottom-extras {
    border: 1px solid light.$ge-border-default;
    background-color: light.$surface-secondary-default;
    border-radius: corner-radius.$xs;

    > * + * {
      border-top: 1px solid light.$ge-border-default;
    }
  }

  &__color-wrapper-bottom-extras-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: spacing.$xs;
  }


  &__color {
    width: 18px;
    height: 18px;
    border-radius: corner-radius.$xs;
    margin-top: 3px; // NOTE: Custom px to align box with text
    flex-shrink: 0;

    &--red {
      background-color: light.$ge-signal-error;
    }

    &--orange {
      background-color: light.$ge-signal-warning;
    }

    &--green {
      background-color: light.$ge-signal-success;
    }
  }

  &__color-top-texts {
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
  }

  &__info-text-wrapper {
    margin-top: spacing.$m;

    > * + * {
      margin-top: spacing.$m;
    }
  }

  &__info-texts {
    > * + * {
      margin-top: spacing.$xxs;
    }
  }
}
