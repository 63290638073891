@use '@sats-group/ui-lib/tokens/spacing';

.header {
  margin-bottom: spacing.$l;
  &__wrapper {
    display: flex;
    gap: spacing.$m;
    align-items: center;
  }


  &__text-wrapper {
    > * {
      margin-top: spacing.$s;
    }
  }
}
