@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.input-button {
  position: relative;
  display: inline-block;
  border-radius: corner-radius.$xs;
  border: 1px solid light.$buttons-secondary-default;
  &:hover {
    background-color: light.$buttons-secondary-hover;
  }

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  label {
    display: block;
    box-sizing: border-box;
    padding: (spacing.$xs - 3.5px) (spacing.$s - 1);
    text-align: center;
    background: transparent;
    color: light.$buttons-secondary-default;
    text-transform: uppercase;

  }

  input:focus + label {
    outline: 4px solid light.$buttons-secondary-default;
    outline-offset: 2px;
  }
}
