@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.list-modal {
  &__options {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: spacing.$xl;
    row-gap: spacing.$m;
    margin: 0;
    padding: 0;
    border: none;

    @media (min-width: 640px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__options-item {
    border-bottom: 1px solid light.$ge-divider-default;
    padding-bottom: spacing.$xs;
  }

  &__section {
    background: light.$surface-primary-default;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    margin-bottom: spacing.$s;
  }

  &__button-wrapper {
    display: flex;
    justify-content: center;
  }

  &__select {
    width: 150px;
  }
}
