@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.session-list {
  &__title {
    margin-bottom: spacing.$s;
  }

  &__list-title {
    margin-bottom: spacing.$m;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &__button {
    margin-top: spacing.$xs;
    margin-bottom: -(spacing.$xxs)
  }

  &__toggle {
    appearance: none;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: spacing.$s 0;
  }
}
