.header {
  margin-bottom: 24px;
}
.header__wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}
.header__text-wrapper > * {
  margin-top: 12px;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9oZWFkZXIiLCJzb3VyY2VzIjpbImhlYWRlci5zY3NzIiwiLi4vLi4vLi4vbm9kZV9tb2R1bGVzL0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZy5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsZUNFRTs7QURERjtFQUNFO0VBQ0EsS0NGQTtFREdBOztBQUtBO0VBQ0UsWUNWRiIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgJ0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZyc7XG5cbi5oZWFkZXIge1xuICBtYXJnaW4tYm90dG9tOiBzcGFjaW5nLiRsO1xuICAmX193cmFwcGVyIHtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDogc3BhY2luZy4kbTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICB9XG5cblxuICAmX190ZXh0LXdyYXBwZXIge1xuICAgID4gKiB7XG4gICAgICBtYXJnaW4tdG9wOiBzcGFjaW5nLiRzO1xuICAgIH1cbiAgfVxufVxuIiwiLy8gaHR0cHM6Ly93d3cuZmlnbWEuY29tL2ZpbGUvV3pLQ3dSWTA5em40cnpSVmZZMFl2ZFJ0L3NhdHMtZHMtc3R5bGVzP25vZGUtaWQ9NDA4JTNBODVcbiR4eHM6IDRweDtcbiR4czogOHB4O1xuJHM6IDEycHg7XG4kbTogMTZweDtcbiRsOiAyNHB4O1xuJHhsOiAzMnB4O1xuJHh4bDogNjRweDtcbiR4eHhsOiAxMjhweDtcblxuJGhlcm86IDI1NnB4O1xuJG1pbmltdW0tdmlld3BvcnQtd2lkdGg6IDMyMHB4O1xuIl19 */