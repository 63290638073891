@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.daily-sessions {
  $breakpoint: 600px;

  &__header {
    margin-bottom: spacing.$s;

    @media (min-width: $breakpoint) {
      margin-bottom: spacing.$m;
    }
  }

  &__list {
    overflow: hidden;
    border-radius: spacing.$s;
  }
}
