@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.list-form {
  $breakpoint: 600px;
  position: relative;
  margin-bottom: spacing.$l;

  &__header-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid light.$ge-divider-default;
    margin-bottom: spacing.$m;
    padding-bottom: spacing.$m;
    gap: 60px;
  }

  &__sub-header {
    margin-bottom: spacing.$m;
  }

  &__search {
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint) {
      flex-direction: row;
    }
  }

  &__add-items {
    margin-bottom: spacing.$xl;
  }

  &__inputs {
    display: flex;
    gap: spacing.$s;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: spacing.$l;

    @media (min-width: $breakpoint) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: spacing.$s;
    width: 100%;
  }

  &__input-inner-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: spacing.$m;
  }

  &__input-order {
    display: flex;
    align-items: center;
  }

  &__input-order-header {
    margin-right: spacing.$xs;
    @media (max-width: $breakpoint) {
      display: none;
    }
  }

  &__text-input {
    margin-top: spacing.$xs;
    flex-grow: 1;
  }

  &__input-inner-bottom {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: spacing.$l;

    @media (min-width: $breakpoint) {
      flex-direction: row;
    }
  }

  &__input-remove {
    align-self: flex-end;
  }

  &__buttons {
    > * + * {
      margin-left: spacing.$xs;
    }
  }

  &__no-selected {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: spacing.$s;
  }
}
