@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.filter-accordion {
  $breakpoint: 700px;
  &__toggle-wrapper {
    border-bottom: 1px solid lightmode.$ui-shimmer;
    background-color: lightmode.$clean;
    width: 100%;
  }

  &__toggle {
    appearance: none;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: inherit;
    color: inherit;
    display: flex;
    align-items: center;
    width: 100%;
    padding: spacing.$s;
  }

  &__filter-wrapper {
    background-color: lightmode.$clean;
    padding: spacing.$xl 0 spacing.$l;
    width: 100%;
  }

  &__text {
    margin-left: spacing.$s;
    margin-right: auto;
    @media (min-width: $breakpoint) {
      margin-right: spacing.$l;
    }
  }
}
