@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';

.video-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;

  &__player {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 50vh;
    background-color: rgba(0, 0, 0, 1);
  }

  &__error {
    background-color: light.$signal-surface-error;
    color: light.$on-signal-surface-error-default;
    padding: spacing.$m;
  }

  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }

  &__spinner-wrapper {
    position: absolute;
    z-index: 2;
  }
}
