@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/spacing';

.member-action {
  &__buttons {
    display: flex;
    height: 100%;
    > * {
      flex: 1;
    }
  }

  &__submit {
    width: 100%;
  }
}
