@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.member-information {
  $breakpointSmall: 500px;
  $breakpoint: 600px;

  &__section {
    padding: spacing.$m;
    border-radius: corner-radius.$s;

    @media (min-width: $breakpointSmall) {
      min-width: 400px;
    }

    @media (min-width: $breakpoint) {
      min-width: 500px;
    }

    &--surface {
      background-color: light.$surface-primary-default;
    }

    &:not(:last-child) {
      margin-bottom: spacing.$m;
    }

    &--without-side-or-bottom-padding {
      padding: spacing.$m 0 0;
    }

    &--without-padding {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: spacing.$s;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: spacing.$l;
    margin-bottom: spacing.$xs;
    gap: spacing.$xs;
  }

  &__messages {
    margin: spacing.$s 0;
  }

  &__detail,
  &__icon {
    color: light.$ge-icons-secondary;
  }

  &__contact {
    display: flex;
    align-content: center;
    margin-right: spacing.$l;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__basic-information {
    margin-bottom: spacing.$l;
  }

  &__image-wrapper {
    display: flex;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    overflow: hidden;
    flex-shrink: 0;
    color: light.$ge-icons-secondary;
    background-color: light.$surface-primary-default;
  }

  &__information {
    display: flex;
    justify-content: space-between;
  }

  &__product {
    &:not(:last-of-type) {
      margin-bottom: spacing.$m;
    }

    >*+* {
      margin-bottom: spacing.$xs;
    }
  }

  &__member-details {
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: spacing.$xs;
  }

  &__session-list {
    padding: 0 spacing.$m;
  }

  &__session-list-wrapper {
    border-top: 1px solid light.$ge-divider-default;
  }

  &__refresh {
    margin-top: spacing.$m;
  }

  &__products {
    padding: 0 spacing.$m;
  }

  &__product-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$l;

    &--padding-top-bottom {
      padding: spacing.$m 0;
    }
  }

  &__expired-products {
    margin-top: spacing.$l;
    padding: spacing.$m;
    border-top: 1px solid light.$ge-divider-default;
  }

  &__expired-button {
    padding: 0;
    border: 0;
    background: none;

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
