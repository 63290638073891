@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.my-member {
  display: flex;
  align-items: flex-start;
  background: light.$surface-primary-default;
  width: 100%;

  &:hover {
    border-radius: corner-radius.$s;
    background-color: light.$surface-primary-hover;
  }

  &:not(:first-of-type) {
    border-top: 1px solid light.$ge-divider-default;
  }

  &__link {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
    text-decoration: none;
    padding: spacing.$s 0 spacing.$s spacing.$m;
    flex-grow: 1;
  }

  &__info {
    display: flex;
    gap: spacing.$xxs;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__secondary-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: light.$on-surface-primary-alternate;
  }

  &__tags {
    display: flex;
    gap: spacing.$xs;
  }

  &__options {
    position: relative;
  }
}
