@use '@sats-group/ui-lib/tokens/corner-radius';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/lightmode';

.pagination {
  $breakpoint: 700px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint) {
    width: 100%;
  }

  &__select {
    margin-right: spacing.$m;
    width: 100%;
    @media (min-width: $breakpoint) {
      display: none !important;
    }
  }

  &__links {
    display: none;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: $breakpoint) {
      display: flex;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    min-width: 38px;
    border-radius: corner-radius.$l;
    border: 1px solid transparent;
    padding: 0 spacing.$xs;
    text-decoration: none;
    margin-right: spacing.$m;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border-color: lightmode.$primary-hover;
    }

    &--current {
      background-color: lightmode.$primary;
      color: lightmode.$on-primary;
    }

    &--iterator {
      background-color: lightmode.$background-secondary;
      border: 1px solid lightmode.$ui-border;
      padding: 0;

      &:hover {
        border-color: lightmode.$ui-border;
        background-color: lightmode.$clean-secondary;
      }

      @media (max-width: $breakpoint) {
        min-width: 48px;
        height: 48px;
      }
    }

    &--disabled {
      @media (min-width: $breakpoint) {
        display: none;
      }
    }
  }
}
