@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.new-profile {
  $breakpoint: 500px;
  margin-bottom: spacing.$xl;

  &__header-wrapper {
    margin-bottom: spacing.$xl;
    display: flex;
    justify-content: space-between;
  }

  &__info-modal {
    display: flex;
    flex-direction: column;
    gap: spacing.$m;
  }

  &__header-buttons {
    display: flex;
    align-items: center;
    gap: spacing.$s;
  }

  &__info-modal-star {
    color: light.$on-surface-featured;
  }

  &__info-modal-problems {
    display: flex;
    gap: spacing.$xs;
  }

  &__sections {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    margin-top: spacing.$xl;
  }

  &__section-image-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    background-color: light.$background-secondary-default;
    position: relative;
  }

  &__section-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: light.$on-buttons-on-primary-default;

    padding: 0;
    margin: 0;
    background: none;
    border-radius: 100%;
    border: none;

    &:hover {
      background-color: rgba(0, 0, 0, .2); // NOTE: This is a transparent colour on the image with a non-transparent icon inside

      .new-profile__section-image-showing {
        display: block;
      }
    }
  }

  &__section-image-showing {
    display: none;
  }

  &__section-inner {
    display: flex;
    flex-direction: column;

    @media (max-width: $breakpoint) {
      align-items: center;
    }
  }

  &__section-user-info,
  &__section-placeholder {
    color: light.$on-surface-primary-alternate;
  }

  &__modal-inner {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__modal-link {
    margin-left: spacing.$xxs;
  }

  &__section-placeholder,
  &__section-contact-info {
    display: flex;
    align-items: center;
    gap: spacing.$s,
  }

  &__section-video {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__section-availability-days {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }

  &__section-availability-day {
    display: flex;
    justify-content: space-between;
    gap: spacing.$xs;
  }

  &__buttons {
    display: flex;
    gap: spacing.$s;
    width: 100%;
    justify-content: center;
    padding: 0 spacing.$l;
    margin-top: spacing.$l;
  }

  &__reject-buttons {
    margin-top: spacing.$s;
    display: flex;
    gap: spacing.$s;
    justify-content: space-between;
    width: 100%;

    @media(min-width: $breakpoint) {
      min-width: 400px;
    }
  }

  &__ul {
    margin: 0;
    > * + * {
      margin-top: spacing.$xs;
    }
  }
}
