@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.section {
  $breakpoint: 600px;

  display: flex;
  flex-direction: column;
  background-color: light.$background-secondary-default;
  border-radius: corner-radius.$m;
  margin-bottom: spacing.$m;

  @media (min-width: $breakpoint) {
    margin-bottom: spacing.$l;
  }

  &--spacing {
    gap: spacing.$xs;
    padding: spacing.$s;

    @media (min-width: $breakpoint) {
      gap: spacing.$m;
      padding: spacing.$m;
    }
  }

  &--connectable-bottom {
    border-radius: corner-radius.$m corner-radius.$m 0 0;
    margin-bottom: 0;
  }

  &--connectable-top {
    border-radius: 0 0 corner-radius.$m corner-radius.$m;
    margin-top: 0;
    padding-top: 0;
  }
}
