@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/corner-radius';

.content-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: spacing.$m;
  padding: spacing.$m;
  background-color: light.$surface-primary-default;
  border-radius: corner-radius.$s;
}
