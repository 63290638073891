.content-container {
  margin: 0 auto;
  max-width: 800px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.content-container--wide {
  max-width: 1000px;
}
.content-container--full-width {
  max-width: 1280px;
}
@media (max-width: 500px) {
  .content-container--no-padding-mobile {
    padding: 0;
  }
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2hvbWUvdnN0cy93b3JrLzEvcy9zaGFyZWQtdWkvY29tcG9uZW50cy9jb250ZW50LWNvbnRhaW5lciIsInNvdXJjZXMiOlsiY29udGVudC1jb250YWluZXIuc2NzcyIsIi4uLy4uLy4uL25vZGVfbW9kdWxlcy9Ac2F0cy1ncm91cC91aS1saWIvdG9rZW5zL3NwYWNpbmcuc2NzcyIsIi4uLy4uL2JyZWFrcG9pbnRzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0E7RUFDRTtFQUNBO0VBQ0EsY0NGRTtFREdGLGVDSEU7RURJRjs7QUFFQTtFQUNFOztBQUdGO0VBQ0U7O0FFZEY7RUZpQkE7SUFFSSIsInNvdXJjZXNDb250ZW50IjpbIkB1c2UgJ0BzYXRzLWdyb3VwL3VpLWxpYi90b2tlbnMvc3BhY2luZyc7XG5AdXNlICcuLi8uLi9icmVha3BvaW50cy5zY3NzJztcblxuLmNvbnRlbnQtY29udGFpbmVyIHtcbiAgbWFyZ2luOiAwIGF1dG87XG4gIG1heC13aWR0aDogODAwcHg7XG4gIHBhZGRpbmctbGVmdDogc3BhY2luZy4kbTtcbiAgcGFkZGluZy1yaWdodDogc3BhY2luZy4kbTtcbiAgd2lkdGg6IDEwMCU7IC8vIE5PVEU6IFRoaXMgaXMgbmVlZGVkIGZvciByZW5kZXJpbmcgaW5zaWRlIGZsZXhib3ggY29udGFpbmVyc1xuXG4gICYtLXdpZGUge1xuICAgIG1heC13aWR0aDogMTAwMHB4O1xuICB9XG5cbiAgJi0tZnVsbC13aWR0aCB7XG4gICAgbWF4LXdpZHRoOiAxMjgwcHg7XG4gIH1cblxuICAmLS1uby1wYWRkaW5nLW1vYmlsZSB7XG4gICAgQGluY2x1ZGUgYnJlYWtwb2ludHMuYnJlYWsge1xuICAgICAgcGFkZGluZzogMDtcbiAgICB9XG4gIH1cbn1cbiIsIi8vIGh0dHBzOi8vd3d3LmZpZ21hLmNvbS9maWxlL1d6S0N3UlkwOXpuNHJ6UlZmWTBZdmRSdC9zYXRzLWRzLXN0eWxlcz9ub2RlLWlkPTQwOCUzQTg1XG4keHhzOiA0cHg7XG4keHM6IDhweDtcbiRzOiAxMnB4O1xuJG06IDE2cHg7XG4kbDogMjRweDtcbiR4bDogMzJweDtcbiR4eGw6IDY0cHg7XG4keHh4bDogMTI4cHg7XG5cbiRoZXJvOiAyNTZweDtcbiRtaW5pbXVtLXZpZXdwb3J0LXdpZHRoOiAzMjBweDtcbiIsIkBtaXhpbiBicmVhayB7XG4gIEBtZWRpYSAobWF4LXdpZHRoOiA1MDBweCkge1xuICAgIEBjb250ZW50O1xuICB9XG59XG4iXX0= */