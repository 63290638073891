@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.session-list-session {
  padding-bottom: spacing.$xs;
  border-bottom: 1px solid light.$ge-divider-default;
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    margin-top: spacing.$xxs;
  }

  &:not(:last-of-type) {
    margin-bottom: spacing.$xs;
  }

  &__context-button {
    position: relative;
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    color: light.$on-surface-primary-alternate;
  }

  &__details-item-dot {
    display: inline-block;
    vertical-align: middle;
    background-color: currentColor;
    height: 6px;
    width: 6px;
    border-radius: 100%;
    margin: 0 spacing.$s;
  }
}
