@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';

.recurring-session {
  background: light.$surface-primary-default;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  position: relative;
  border-top: 1px solid light.$ge-border-default;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: spacing.$s;
    background: light.$surface-primary-default;
  }

  &--theme-error {
    &::before {
      background: light.$ge-signal-error;
    }
  }

  &--theme-success {
    &::before {
      background: light.$ge-signal-success;
    }
  }

  &--theme-warning {
    &::before {
      background: light.$ge-signal-warning;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: spacing.$s spacing.$s spacing.$s (spacing.$s * 2);
    background: none;
    color: inherit;
    border: none;
    font: inherit;
  }

  &__time {
    display: flex;
    gap: spacing.$s;
    flex-wrap: wrap;
  }

  &__time-detail {
    display: flex;
    align-items: center;
    align-content: center;
    gap: spacing.$xs;
    min-width: 130px; // NOTE: Custom width to make date and time more similar in width
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 spacing.$s spacing.$s (spacing.$s * 2);
  }

  &__messages-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }
}
