@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.member-toolbox {
  background: light.$surface-primary-default;
  border-radius: corner-radius.$s;
  padding-top: spacing.$m;

  &--theme-inline {
    border-radius: 0 0 corner-radius.$s corner-radius.$s;
  }

  &__header {
    padding: 0 spacing.$m;
  }

  &__contact-wrapper {
    padding: spacing.$xs spacing.$m;
  }

  &__copy {
    display: flex;
    align-items: center;
    gap: spacing.$xxs;
  }

  &__icon {
    display: flex;
    margin-bottom: -2px; //NOTE: Hack to make it align as the design
    color: light.$on-surface-primary-alternate;
  }
}
